import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Carousel, Formrick, Modal, RippleButton } from '@uniqs-labs/ui-react-lib'
import { useSession } from 'next-auth/react'

import Image from 'next/image'
import AppHeader from '../../components/AppHeader'
import useMyTemplate from '../../hooks/useMyTemplate'
import useMyTeam from '../../hooks/useMyTeam'
import AppSection from '../../components/AppSection'
import styles from '../../styles/pages/app/AppHome.module.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/grid'
import '@uniqs-labs/ui-react-lib/carousel'
import CheckoutForm from '../../components/CheckoutForm'

export default function MailBuilder() {
  const router = useRouter()
  const { data: session } = useSession()
  const { data: teams } = useMyTeam()
  const { data, createTemplate, setTemplate, duplicateTemplate, deleteTemplate, loading } =
    useMyTemplate()
  const [showBillingModal, setShowBilling] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<null | {
    templateTitle: string
    templateId: string
    authorEmail: string
  }>(null)

  const { templates } = data
  const selectedTeam = teams.selected
  const currentTeamId = selectedTeam?.id
  const user = selectedTeam?.memberships?.find(
    (membership) => membership.email === session?.user.email,
  )
  const userAdmin = user?.admin

  const inputs = {
    createTemplate: [
      {
        id: 'templateTitle' as 'templateTitle',
        title: 'Template title',
        type: 'text' as 'text',
        required: true,
        value: '',
      },
    ],
  }

  const userTemplates = (
    <Carousel
      pagination
      navigation
      preventClicksPropagation={false}
      preventClicks
      loop={false}
      autoplay={false}
      spaceBetween={{
        start: 0,
        s: 0,
        m: 0,
        l: 0,
      }}
      slidesPerView={{
        start: 1,
        s: 2,
        m: 2,
        l: 2,
      }}
      slides={
        templates?.length
          ? templates
              ?.filter(
                (template) =>
                  template.authorEmail === session?.user.email &&
                  template.teamId === currentTeamId &&
                  !template.published,
              )
              ?.sort(
                (templateA, templateB) =>
                  Number(new Date(templateB.updatedAt)) - Number(new Date(templateA.updatedAt)),
              )
              ?.map((template) => ({
                content: (
                  <div className={styles['new-edit-grid']}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setSelectedTemplate({
                          templateTitle: template.title,
                          templateId: template.id,
                          authorEmail: template.authorEmail,
                        })
                      }}
                      className={styles['thumbnail-button-wrapper']}
                    >
                      <div className={styles['thumbnail-wrapper']}>
                        <Image
                          height={200}
                          width={160}
                          layout="responsive"
                          src="https://uniqs-public-assets-bucket.s3.eu-central-1.amazonaws.com/mailbuilder/preview-mail.png"
                        />
                      </div>
                    </button>
                    <h5>{template.title}</h5>
                    <h6>Last updated: {new Date(template.updatedAt).toLocaleDateString()}</h6>
                  </div>
                ),
              }))
          : [{ content: <div /> }]
      }
    />
  )

  const teamTemplates = (
    <Carousel
      pagination
      navigation
      preventClicksPropagation={false}
      preventClicks
      loop={false}
      autoplay={false}
      spaceBetween={{
        start: 0,
        s: 0,
        m: 0,
        l: 0,
      }}
      slidesPerView={{
        start: 1,
        s: 2,
        m: 2,
        l: 2,
      }}
      slides={
        templates?.length
          ? templates
              ?.filter((template) => template.teamId === currentTeamId && template.published)
              ?.sort(
                (templateA, templateB) =>
                  Number(new Date(templateB.updatedAt)) - Number(new Date(templateA.updatedAt)),
              )
              ?.map((template) => ({
                content: (
                  <div className={styles['new-edit-grid']}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setSelectedTemplate({
                          templateTitle: template.title,
                          templateId: template.id,
                          authorEmail: template.authorEmail,
                        })
                      }}
                      className={styles['thumbnail-button-wrapper']}
                    >
                      <div className={styles['thumbnail-wrapper']}>
                        <Image
                          height={200}
                          width={160}
                          layout="responsive"
                          src="https://uniqs-public-assets-bucket.s3.eu-central-1.amazonaws.com/mailbuilder/preview-mail.png"
                        />
                      </div>
                    </button>
                    <h5>{template.title}</h5>
                    <h6>Last updated: {new Date(template.updatedAt).toLocaleDateString()}</h6>
                  </div>
                ),
              }))
          : [{ content: <div /> }]
      }
    />
  )

  const handleSubmit = (reqForm: typeof inputs.createTemplate) => {
    const templateName = reqForm.find((input) => input.id === 'templateTitle')?.value as string
    if (!templateName) return
    if (
      templates &&
      selectedTeam?.billing &&
      templates.filter((template) => template.teamId === selectedTeam.id).length >=
        selectedTeam?.billing[0].templatesAllowed
    ) {
      setShowBilling(true)
      return
    }
    createTemplate({
      inputs: { title: templateName },
      callback: () => router.push('/app/editor'),
    })
  }

  return (
    <>
      <AppHeader
        title="Mail Builder"
        links={[
          {
            onClick: () => router.push('/app/feedback'),
            label: 'Click here to provide feedback!',
          },
        ]}
      />
      <AppSection>
        <Formrick<typeof inputs.createTemplate>
          loading={loading}
          inputs={inputs.createTemplate}
          mainTitle="Create mail template"
          onSendFormRequest={handleSubmit}
          submitText="Create mail template"
        />
      </AppSection>

      <AppSection>
        <h4>Your templates:</h4>
        <br />
        <br />
        {userTemplates}
      </AppSection>
      <AppSection>
        <h4>Team templates:</h4>
        <br />
        <br />
        {teamTemplates}
      </AppSection>
      {showBillingModal && selectedTeam && userAdmin && (
        <Modal onClose={() => setShowBilling(false)}>
          <h3>You have reached your template limit.</h3>
          <p>Please upgrade your plan.</p>
          <CheckoutForm
            selectedTeamId={selectedTeam?.id}
            priceId={selectedTeam?.billing[0].selectedPriceId}
          />
        </Modal>
      )}
      {showBillingModal && selectedTeam && !userAdmin && (
        <Modal onClose={() => setShowBilling(false)}>
          <h3>Your team have reached your template limit.</h3>
          <p>Please contact your administrator to upgrade the plan.</p>
        </Modal>
      )}
      {selectedTemplate && (
        <Modal onClose={() => setSelectedTemplate(null)}>
          <div className={styles['new-edit-grid']}>
            <div className={styles['thumbnail-in-modal-wrapper']}>
              <div className={styles['thumbnail-wrapper']}>
                <Image
                  height={200}
                  width={160}
                  layout="responsive"
                  src="https://uniqs-public-assets-bucket.s3.eu-central-1.amazonaws.com/mailbuilder/preview-mail.png"
                />
              </div>
            </div>
            <h5>{selectedTemplate.templateTitle}</h5>
            <h6>Author: {selectedTemplate.authorEmail}</h6>
          </div>
          <br />
          <br />
          <br />
          <RippleButton
            onClick={() => {
              setTemplate({
                inputs: {
                  templateId: selectedTemplate.templateId,
                },
                callback: () => {
                  setSelectedTemplate(null)
                  router.push('/app/editor')
                },
              })
            }}
            primary
            size="small"
            label="Edit"
          />
          <RippleButton
            onClick={() => {
              if (
                templates &&
                selectedTeam?.billing &&
                templates.filter((template) => template.teamId === selectedTeam.id).length >=
                  selectedTeam?.billing[0].templatesAllowed
              ) {
                setShowBilling(true)
                setSelectedTemplate(null)
                return
              }
              duplicateTemplate({
                inputs: {
                  templateId: selectedTemplate.templateId,
                  authorEmail: selectedTemplate.authorEmail,
                },
              })
              setSelectedTemplate(null)
            }}
            size="small"
            label="Duplicate"
          />
          <RippleButton
            onClick={() => {
              deleteTemplate({
                inputs: {
                  templateId: selectedTemplate.templateId,
                },
              })
              setSelectedTemplate(null)
            }}
            size="small"
            warning
            label="Delete"
          />
        </Modal>
      )}
    </>
  )
}

MailBuilder.auth = {
  authRequired: true,
  teamRequired: true,
}
